@use '@styles/theme.module' as *;

.how_to {
  &_list {
    // size of list markers
    padding-left: 28px;

    &_item {
      color: $grayDark;
      margin-bottom: spacing(3);
    }
  }
}

.title {
  @include typographyH5Medium;
}
