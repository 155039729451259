@use '@styles/theme.module' as *;

.payment {
  &_title {
    margin: 0 0 spacing(2) 0;
    color: $black;

    @include typographyH5;
  }

  &_icon {
    height: 16px;
    width: auto;
    margin-right: spacing(2);
  }

  &_line {
    display: flex;
    align-items: center;
  }

  &_value {
    color: $grayDark;
    @include media($from: $lg) {
      @include typographyH5;
    }
  }
}
