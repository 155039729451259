@use '@styles/theme.module' as *;

.right_side {
  height: 100%;
  overflow-y: auto;
}

.order_info_block {
  width: 100%;
  padding: spacing(14) spacing(11) spacing(11) spacing(11);

  &_title {
    @include typographyH3;
  }

  &_divider {
    margin: spacing(7) 0;
  }
}

.submit_button {
  border-top: 1px solid $grayLighter;
  background-color: $gray1;
  padding: 0 spacing(4);
  border-right: 1px solid $grayLighter;
}

.modal {
  &_title {
    margin-bottom: spacing(7);
    @include typographyH3;
  }

  &_divider {
    margin-bottom: spacing(7);
  }
}
