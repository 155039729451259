@use '@styles/theme.module.scss' as *;

$sticky-header-height: 96px;
$sticky-header-alert-height: 178px;

.log_out_link {
  color: $black;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin-top: spacing(5);
  @include media($until: $largeTablet) {
    display: none;
  }

  @include typographyButtonsAndLinksMedium;
}

.sidebar {
  position: sticky;
  z-index: 999;
  left: 0;
  top: $sticky-header-height;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: spacing(0);
  background: $white;
  width: 100%;

  @include media($from: $largeTablet) {
    background: $gray1;
    height: 100vh;

    &.has_alert {
      height: calc(100vh - #{$sticky-header-alert-height});
    }
  }

  @include media($from: $xl) {
    padding-top: spacing(9);
  }

  &_scroll {
    justify-content: space-between;

    @include media($from: $largeTablet) {
      &.has_alert {
        height: calc(100vh - #{$sticky-header-height});
      }
    }

    .log_out_link {
      margin-top: 0;
    }
  }
}

.has_alert {
  .log_out_link {
    bottom: spacing(7);
  }
}

.sidebar_wrapper {
  width: 100%;
}

.title_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: spacing(0) spacing(0) spacing(9);

  @include media($until: $largeTablet) {
    display: none;
  }
  @include media($from: $largeTablet) {
    width: 260px;
  }

  @include media($from: $lg) {
    width: 290px;
  }

  @include media($from: $xl) {
    width: 350px;
  }

  h1 {
    width: 100%;
    padding-right: spacing(6);
  }

  & :global(.MuiSkeleton-text) {
    width: 120px;
    display: inline-block;
  }
}

.name,
.email,
.subnav_helper_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email,
.name {
  max-width: 275px;
}

.email {
  color: $grayDark;
  @include typographyBody;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  margin-bottom: spacing(9);

  @include media($from: $sm) {
    height: auto;
  }
}

.list_item {
  width: 100%;
  list-style-type: none;
}
