@keyframes stretch {
  from {
    min-height: 0;
    max-height: 0;
    opacity: 0;
  }

  to {
    min-height: 100px;
    max-height: 300px;
    opacity: 1;
  }
}

@keyframes squeeze {
  from {
    min-height: 100px;
    max-height: 300px;
    opacity: 1;
  }

  to {
    min-height: 0;
    max-height: 0;
    opacity: 0;
  }
}

.line_item {
  &_created {
    animation-name: stretch;
    animation-duration: 1s;
    overflow: hidden;
  }

  &_removed {
    animation-name: squeeze;
    animation-duration: 1s;
    overflow: hidden;
  }

  &:last-child {
    padding-bottom: unset;
  }
}
