@use '@styles/theme.module' as *;

.customer_service {
  display: block;
  margin: spacing(4) 0;

  @include media($from: $sm) {
    margin: spacing(6) 0;
  }

  .text {
    color: $grayDark;
    margin-right: spacing(1);
  }

  + .order_line_ineligible {
    border-top: none;
  }
}
