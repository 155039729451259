@use '@styles/theme.module' as *;

.container {
  &_loading {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.headline {
  margin-bottom: spacing(6);
  @include typographyH3;

  @include media($from: $lg) {
    margin-bottom: spacing(7);
  }

  .title {
    display: block;
    color: $black;
  }

  .order_number {
    display: block;
    color: $grayDark;
  }
}

.order_line_container {
  margin-top: spacing(5);

  @include media($from: $sm) {
    margin-top: spacing(6);
  }

  @include media($from: $lg) {
    margin-top: spacing(8);
  }
}
