@use '@styles/theme.module' as *;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &_disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.payment_divider {
  margin: spacing(7) 0;
}
