@use '@styles/theme.module' as *;

.order_line {
  display: flex;
  padding: spacing(5) 0;
  border-bottom: 1px solid $grayLighter;

  &:last-child {
    border: 0;
  }

  @include media($from: $sm) {
    align-items: center;
    padding: spacing(6) 0;
  }

  @include media($from: $lg) {
    border-top: unset;
    padding-bottom: unset;
  }
}

.image_container {
  display: flex;
  position: relative;
  width: 60px;
  min-width: 60px;
  height: 60px;
  margin-right: spacing(6);
}

.info_container {
  height: 100%;
  display: flex;
  align-items: center;
}

.text_container {
  display: flex;
  padding-right: spacing(3.5);
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.substitute {
  text-transform: uppercase;
  color: $grayDark;
  @include typographyLabelBold;
}

.configuration {
  display: flex;
  flex-direction: column;
  color: $grayDark;
  width: 100%;
  overflow-wrap: break-word;
  @include typographySmallBody;

  &_item {
    display: flex;
    white-space: nowrap;
    border-right: 1px solid $beige;
    padding-right: spacing(1);
    margin-right: spacing(1);

    &:last-child {
      border-right: 0;
    }
  }
}
