@use '@styles/theme.module' as *;

.container {
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.issue_dropdown {
  width: 180px;
  margin-right: spacing(2);

  & :global(.MuiSelect-select) {
    @include typographyBody;
  }
}

.issues_list {
  & > :global(.MuiPaper-root .MuiList-root) {
    max-height: 444px;
  }

  & > :global(.MuiPaper-root .MuiList-root .MuiMenuItem-root) {
    padding-right: spacing(5);
    padding-bottom: spacing(6);
  }
}

.quantity_dropdown {
  width: 80px;
  min-width: 80px;
}
