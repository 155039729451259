@use '@styles/theme.module' as *;

.requested_items {
  &_container {
    margin: spacing(4) 0;

    @include media($from: $sm) {
      margin: spacing(7) 0;
    }
  }

  &_headline {
    color: $grayDark;
    margin-bottom: spacing(4);

    @include media($from: $sm) {
      margin-bottom: spacing(5);
    }
  }

  &_summary {
    margin-top: spacing(4);

    @include media($from: $sm) {
      margin-top: spacing(6);
    }
  }

  &_summary_line {
    display: flex;
    justify-content: space-between;
    color: $grayDark;
    margin-bottom: spacing(2);
    @include typographyBody;

    &_total {
      color: $black;

      &_text {
        @include typographyBodyBold;
        @include media($from: $lg) {
          @include typographySmallBodyBold;
        }
      }
    }
  }
}
