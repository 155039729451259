@use '@styles/theme.module' as *;

.delivery {
  margin-bottom: spacing(6);

  &_title {
    margin: 0 0 spacing(2) 0;
    color: $black;
    @include typographyH5;
  }

  &_value {
    color: $grayDark;
    @include typographyH5;
  }
}
