@use '@styles/theme.module' as *;

.more_block {
  &_field {
    margin-top: spacing(6);
    @include typographyBody;

    &:hover fieldset {
      border-color: $primary !important;
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    height: 100%;

    &_content {
      flex: 1 1 auto;
    }
  }

  .more_link {
    @include typographyButtonsAndLinks;
  }
}

.error_alert {
  max-width: 100%;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
}

.submit_button {
  margin-top: spacing(7);
  @include typographyButtonsAndLinks;

  @include media($from: $sm) {
    margin-top: spacing(11);
  }

  @include media($from: $lg) {
    margin-top: spacing(13);
  }

  &_field_showed {
    @include media($from: $sm) {
      margin-top: spacing(7);
    }

    @include media($from: $lg) {
      margin-top: spacing(4);
    }
  }
}
