@use '@styles/theme.module.scss' as *;

$account-page-side-padding: 60px;

.subnav {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  color: $grayDark;
  width: 100%;
  min-height: 36px;
  margin-bottom: spacing(6);
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;

  &.active::before,
  &:hover::before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    background-color: $primary;
    height: 100%;
    left: -$account-page-side-padding;
  }

  &:hover {
    color: $black;

    svg {
      color: $black;
    }
  }

  &:focus-visible {
    outline: 1px dashed $grayDarker;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @include media($until: $largeTablet) {
    display: none;
  }
}

.subnav_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: spacing(4);
  @include typographyBody;
}

.subnav_helper_text {
  font-style: normal;
  font-weight: normal;
  max-width: 187px;
  margin: spacing(1) 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $grayDark;
  @include typographySmallBody;

  @include media($from: $xl) {
    max-width: 217px;
  }
}
