@use '@styles/theme.module.scss' as *;

.accordion {
  width: 100%;
  padding: 0 spacing(4);

  @include media($from: $sm) {
    padding: 0 7rem;
  }

  @include media($from: $largeTablet) {
    display: none;
  }

  &::before {
    display: none;
  }
}

.accordion_summary {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: $grayLighter 1px solid;
  padding: 0;

  &:global(.Mui-expanded) {
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 100;
    transition: box-shadow 0.2s ease-in-out;
  }

  :global(.MuiAccordionSummary-content) {
    flex-grow: 0;
    align-items: center;

    @include media($from: $sm) {
      margin: spacing(5) 0;
    }
  }

  :global(.MuiAccordionSummary-expandIconWrapper) {
    width: 10px;
    height: 10px;

    svg {
      width: 100%;
      height: 100%;
    }

    svg path {
      stroke: $black;
    }
  }

  ~ :global(.MuiCollapse-root) {
    visibility: visible;
  }

  ~ :global(.MuiCollapse-hidden) {
    display: none;
  }
}

.accordion_details {
  padding: 0;
}

.summary_text {
  margin: 0 spacing(2) 0 spacing(3);
  @include typographyH4Bold;
  @include media($from: $lg) {
    @include typographyH4Medium;
  }
}
