@use '@styles/theme.module' as *;

.requested_items {
  &_container {
    margin-bottom: spacing(4);

    @include media($from: $sm) {
      margin-bottom: spacing(7);
    }
  }

  &_headline {
    color: $grayDark;
    margin-bottom: spacing(4);

    @include media($from: $sm) {
      margin-bottom: spacing(5);
    }
  }
}
