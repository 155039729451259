@use '@styles/theme.module' as *;

.container {
  --container-height: 100%;

  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  height: var(--container-height);
  overflow: hidden;

  &_grid {
    height: 100%;
  }
}

.list_container {
  // submit button height
  height: calc(100% - 80px);
  overflow-y: auto;
  padding-bottom: spacing(6);

  @include media($from: $lg) {
    height: 100vh;
    padding-bottom: spacing(8);
  }
}

.left_side {
  height: 100%;
  background-color: $gray1;
  padding: 0 spacing(4);
  border-right: 1px solid $grayLighter;
  overflow-y: auto;

  @include media($from: $sm) {
    padding: 0 spacing(13);
  }

  @include media($from: $lg) {
    padding: 0 spacing(11);
  }
}

.back_button {
  margin: spacing(6) 0 spacing(8) 0;
  @include typographyBodyMedium;

  @include media($from: $sm) {
    margin-bottom: spacing(9);
  }
}
