@use '@styles/theme.module' as *;

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(4);
}

.button {
  @include typographyButtonsAndLinksMedium;
}
