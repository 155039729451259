@use '@styles/theme.module' as *;

.ad_container {
  padding: spacing(0) spacing(4);
  width: 100%;
  @include media($from: $sm) {
    padding: spacing(0) 112px;
  }

  @include media($from: $md) {
    padding: spacing(0);
  }
}

.account_page {
  width: 100%;
  @include media($from: $largeTablet) {
    border-top: 1px solid $grayLighter;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: spacing(5);
    padding: spacing(0) $account-page-side-padding;
    padding-right: 0;
    background-color: $gray1;
    position: relative;
  }
  @include media($from: $lgbp) {
    display: grid;
    grid-template-columns: repeat(3, 1fr) repeat(9, 1fr);
  }
  @include media($from: $xl) {
    border-top: 3px solid $grayLighter;
  }
}

.sidebar {
  @include media($from: $largeTablet) {
    padding: 0 spacing(0) spacing(9);
    background-color: $gray1;
    grid-column: 1 / span 3;
  }
}

.content {
  position: relative;
  @include media($until: $largeTablet) {
    display: none;
  }
  @include media($from: $largeTablet) {
    border-left: $grayLighter 1px solid;
    grid-column: 4 / span 10;
    background-color: $white;
    padding-top: spacing(9);
    padding-right: $account-page-side-padding;
  }
}
