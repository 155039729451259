@use '@styles/theme.module.scss' as *;

.page_layout {
  height: 100%;
  padding-top: spacing(7);
  padding-bottom: spacing(7);

  @include media($from: $sm) {
    padding-top: spacing(11);
    padding-bottom: spacing(11);
  }

  @include media($from: $md) {
    padding-top: spacing(8);
    padding-left: 30px;
  }

  @include media($from: $lg) {
    padding-right: spacing(11);
    padding-left: 132px;
  }

  @include media($from: $xl) {
    padding: spacing(11) spacing(11) spacing(11) 166px;
  }
}
